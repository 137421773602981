import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
import NotFound from '@/views/NotFound.vue'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Layout',
        component: Layout,
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'Home',
				meta:{title:'首页'},
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
            },
            {
                path: '/cart',
                name: 'Cart',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "Cart" */ '../views/cart/index.vue')
            },
            {
                path: '/product',
                name: 'Product',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "Product" */ '../views/product/index.vue')
            },
            {
                path: '/newslist',
                name: 'newslist',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "Product" */ '../views/newslist/index.vue')
            },
            {
                path: '/detail',
                name: 'newsdetail',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "Product" */ '../views/newslist/detail.vue')
            },
            {
                path: '/user',
                name: 'User',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "User" */ '../views/user/index')
            },
            {
                path: '/orderDteail',
                name: 'OrderDteail',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "OrderDteail" */ '../views/orderDteail/index')
            },
            {
                path: '/orderConfirm',
                name: 'orderConfirm',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "OrderDteail" */ '../views/order_confirm/index')
            },
            {
                path: '/productList',
                name: 'ProductList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "ProductList" */ '../views/productList/index')
            },

            {
                path: '/aboutus',
                name: 'aboutus',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "ProductList" */ '../views/user/components/Aboutus.vue')
            },
            {
                path: '/terms',
                name: 'terms',
	
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "Home" */ '../views/terms.vue')
            },
            {
                path: '/brand-uf',
                name: 'brand-uf',
	
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "Home" */ '../views/user/components/brand-filter/uf/brand-uf.vue')
            },
            {
                path: '/brand-Allstar',
                name: 'brand-Allstar',
	
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "Home" */ '../views/user/components/brand-filter/uf/brand-Allstar.vue')
            },
            {
                path: '/brand-LP',
                name: 'brand-LP',
	
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "Home" */ '../views/user/components/brand-filter/uf/brand-LP.vue')
            },
            {
                path: '/brand-Uhlmann',
                name: 'brand-Uhlmann',
	
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "Home" */ '../views/user/components/brand-filter/uf/brand-Uhlmann.vue')
            },

        ]
    },
    {
        path: '/login',
        name: 'Login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Home" */ '../views/login')
    }
]

const router = new VueRouter({
  routes
})

export default router
